







import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IAction } from '@/interfaces/IAction';
import { PopupActions } from '@store/Popup';
import { IWorkListPopupsState } from '@store/modules/work-list/modules/popups/Interfaces';
import { ITogglePopupPayload } from '@/interfaces/ITogglePopupPayload';

const NSWorkListPopups = namespace('storeWorkList/storeWorkListPopups');

@Component({
   name: 'ConstructActions',

   components: {
       BaseActions: () => import('@components/BaseActions'),
   },
})
export default class ConstructActions extends Vue {
    @NSWorkListPopups.Getter('constructMovingPopupToggler') constructMovingPopupToggler!: boolean;

    @NSWorkListPopups.Action(PopupActions.A_TOGGLE_POPUP)
    togglePopup!: (
        payload: ITogglePopupPayload<IWorkListPopupsState>,
    ) => void;

    actions: IAction[] = [
        {
            label: 'Переместить',
            action: 'move',
        },
    ];

    receiveSelectedConstructsAction(action: IAction) {
        switch (action.action) {
            case 'move':
                this.togglePopup({
                    popupToggler: 'constructMovingPopupToggler',
                    state: !this.constructMovingPopupToggler,
                })
        }
    }
}
